import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { path } from "config/path";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

const CheckoutSummary = ({ orderReference }) => {
    // HOOKS
    const theme = useTheme();
    // DATA
    const [order, setOrder] = useState({});

    // FUNCTIONS
    const getOrderSummary = async () => {
        const res = await fetch(`${path.ciHost}/index.php/api/v1/order/order/${orderReference}`);
        const order = await res.json();
        console.log(order[0], JSON.parse(order[0].orderdetail));
        setOrder(order[0]);
    };

    // USE EFFECTS
    useEffect(() => {
        getOrderSummary();
    }, []);

    // RENDER
    return (
        <Box id="checkout-summary" sx={{ pt: 2 }}>
            <Stack sx={{ p: 2 }} spacing={3}>
                {/* HEADER */}
                <Stack>
                    <Typography variant="h3" gutterBottom>Thank you for your order!</Typography>
                    <Typography variant="h4" fontWeight={"normal"}>Your order reference is: <Typography component="span" sx={{ fontWeight: 600 }}>{order?.ref}</Typography></Typography>
                </Stack>
                {/* CUSTOMER DETAILS */}
                <Stack>
                    <Typography variant="h4" gutterBottom>Customer Details</Typography>
                    {!isEmpty(order) && (
                        <Stack key={`${order?.id}-customer-details`} spacing={1} sx={{ background: theme.palette.grey[300], p: 2 }}>
                            {/* First name */}
                            <Stack direction={"row"} justifyContent={"space-between"}>
                                <Typography variant="h5">First name:</Typography>
                                <Typography variant="h5">{order?.firstname}</Typography>
                            </Stack>
                            {/* Last name */}
                            <Stack direction={"row"} justifyContent={"space-between"}>
                                <Typography variant="h5">Last name:</Typography>
                                <Typography variant="h5">{order?.lastname}</Typography>
                            </Stack>
                            {/* Email */}
                            <Stack direction={"row"} justifyContent={"space-between"}>
                                <Typography variant="h5">Email:</Typography>
                                <Typography variant="h5">{order?.email}</Typography>
                            </Stack>
                            {/* Phone */}
                            <Stack direction={"row"} justifyContent={"space-between"}>
                                <Typography variant="h5">Phone:</Typography>
                                <Typography variant="h5">{order?.phone}</Typography>
                            </Stack>
                            {/* Collection Date */}
                            <Stack direction={"row"} justifyContent={"space-between"}>
                                <Typography variant="h5">Collection Date:</Typography>
                                <Typography variant="h5">{order?.collection && order.collection.split(" ")[0]}</Typography>
                            </Stack>
                            {/* Collection Time */}
                            <Stack direction={"row"} justifyContent={"space-between"}>
                                <Typography variant="h5">Collection Time:</Typography>
                                <Typography variant="h5">{order?.collection && order.collection.split(" ")[1].split(":").slice(0, 2).join(":")}</Typography>
                            </Stack>
                            {/* Note */}
                            <Stack direction={"row"} justifyContent={"space-between"}>
                                <Typography variant="h5">Note:</Typography>
                                <Typography variant="h5">{order?.comment}</Typography>
                            </Stack>
                        </Stack>
                    )}
                </Stack>
                {/* ORDER SUMMARY */}
                <Stack>
                    <Typography variant="h4" gutterBottom>Order Summary</Typography>
                    {!isEmpty(order) && (
                        <Box key={`${order?.id}-order-summary`} sx={{ background: theme.palette.grey[300] }}>
                            {/* Items */}
                            {order?.orderdetail && JSON.parse(order.orderdetail).map((item, index) => {
                                const topping = item.topping ? JSON.parse(item.topping) : [];
                                return (
                                    <Stack key={`${item.id}-${index}`} spacing={0.5} sx={{ my: 2, px: 2 }}>
                                        <Stack direction={"row"} justifyContent={"space-between"}>
                                            <Typography variant="h5">{item.name}</Typography>
                                            <Typography variant="h5">{item.size}</Typography>
                                        </Stack>
                                        { item.color && (
                                            <Stack direction={"row"} justifyContent={"space-between"}>
                                                <Typography variant="h5">{item.color}</Typography>
                                            </Stack>
                                        )}
                                        { item.customImage && (
                                            <Stack direction={"row"} justifyContent={"space-between"}>
                                                <Typography variant="h5">{item.customImage}</Typography>
                                            </Stack>
                                        )}
                                        { item.customMsg && (
                                            <Stack direction={"row"} justifyContent={"space-between"}>
                                                <Typography variant="h5">{item.customMsg}</Typography>
                                            </Stack>
                                        )}
                                        {!isEmpty(topping) && (topping.sale_price>0) && (
                                            <Stack direction={"row"} justifyContent={"space-between"}>
                                                <Typography variant="h5">Topping</Typography>
                                                <Typography variant="h5">£{topping?.sale_price}</Typography>
                                            </Stack>
                                        )}
                                        <Stack direction={"row"} justifyContent={"space-between"}>
                                            <Typography variant="h5">Qty: {item.quantity}</Typography>
                                            <Typography variant="h5">£{item.final_price}{!isEmpty(topping) && (topping.sale_price>0)  ? ` + £${topping?.sale_price}` : ""} ea.</Typography>
                                        </Stack>
                                    </Stack>
                                );
                            })}
                            {/* Divider */}
                            <Divider sx={{ borderColor: theme.palette.grey[500] }} />
                            
                            { (order?.discount != undefined) && JSON.parse(order.discount).map((disc, index) => {
                                return (
                                    <Stack direction={"row"} justifyContent={"space-between"} sx={{ px: 2, py:1 }}>
                                        <Typography variant="h5">{disc?.name}</Typography>
                                        <Typography variant="h5">{disc?.percent}%</Typography>
                                    </Stack>
                                )}
                            )}
                            
                            {/* Total */}
                            <Stack direction={"row"} justifyContent={"space-between"} sx={{ px: 2, py: 1 }}>
                                <Typography variant="h5">Total (inc. VAT):</Typography>
                                <Typography variant="h5">£{order?.totalamt}</Typography>
                            </Stack>
                        </Box>
                    )}
                </Stack>
            </Stack>
        </Box>
    );
};

export default CheckoutSummary;