import { Box, Divider, Grid, Stack, Typography, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { path } from 'config/path';
import { StoreContext } from "layout/HomeLayout";
import { ExpandMore } from "@mui/icons-material";
import { fCurrency } from "utils/formatNumber";
import { useGlobalContext } from "context";

// CAKE GRID MENU
const MenuMCakeGrid = ({menus}) => {
	// HOOKS
	const currency = useGlobalContext().currency;
	const { category } = useParams();
	const { store } = useContext(StoreContext);
	const navigate = useNavigate();
	// DATA
	const [menu, setMenu] = useState({main:category, items:[]});
	const [discounts, setDiscounts] = useState(false);

	// FUNCTIONS
	const handleClick = (model) => {
		navigate(`/product-info/${model}`);
	};

	// USE EFFECTS
	useEffect(async () => {
		//console.log(menus);
		async function getDiscount() {
			const res = await fetch(`${path.ciHost}/index.php/api/v1/order/discount/${store.id}/2`);
			const data = await res.json();
			console.log(data);
			return data;
		}
		const discount = await getDiscount();
		if (discount.length>0) {
			console.log(menu.main);
			discount.map((disc) => {
				if (disc.strparam == menu.main) {
					console.log('discount');
					setDiscounts(true);
				}
			})	
		}

		let cat = {main:{name:category}, items:[]};
		let premodel = '';
		menus.map((menu)=>{
			if (menu.model != premodel) {
				cat.items.push(menu);
				premodel = menu.model
			}
			else if (menu.model=='') {
				cat.main = menu;
			}
		})
		setMenu(cat); 
	}, [menus]);

	console.log(menu);
	// RENDER
	return (
		<Box sx={{ mb: 4, px: 2 }}>
			{/* <Typography variant="h3" sx={{ mt: 2, textTransform: "capitalize" }}>
				{menu.main.name}
			</Typography>
			<hr/>
			<Typography variant="body1" sx={{ whiteSpace:'break-spaces' }}>{menu.main.note}</Typography>
			*/}
			{ menu.main.note ? (
				<Accordion disableGutters square>
					<AccordionSummary expandIcon={<ExpandMore />} sx={{p:0, minHeight:0 }}>
						<Typography variant="h3">{menu.main.name}</Typography>
					</AccordionSummary>
					<AccordionDetails sx={{p:0}}>
						<Typography sx={{ textAlign: "justify" }} variant="body1">
							{menu.main.note}
						</Typography>
					</AccordionDetails>
				</Accordion>
			) : (
				<>
					<Typography variant="h3" sx={{ mt: 2, textTransform: "capitalize" }}>
						{menu.main.name}
					</Typography>
					<hr/>
				</>
			)}
			{/* Divider */}
			{/* <Divider sx={{ borderBottomWidth: 2, mt: 2 }} /> */}
			<Typography variant="h4" color='red'>{menu.main.name1}</Typography>
			{/* Grid */}
			<Grid container spacing={2} sx={{ mt: 2 }}>
				{
					menu.items.map((menu, index) => {
						const isDiscount = Boolean(discounts && menu?.discount_price>0 && menu?.sale_price && menu.discount_price < menu.sale_price);
						return (
						<Grid key={index} item xs={6} md={3} onClick={() => handleClick(menu.model)}>
							<Stack spacing={1} sx={{ mb: 0 }}>
								<Box
									sx={{
										borderRadius: '2em',
										width: "100%",
										height: {xs:250, md:200},
										overflow: "hidden",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										border: "1px solid #ccc",
									}}>
									<img 
										src={menu.image ? `${path.imageServer}${path.imageDirectory}/${store.key}/${menu.image}`: `${path.basename}/images/icon-product.svg`}
										alt={menu.name} style={{ 
											objectFit: "cover", 
											width: "100%",
											transform:"scale(1.4)",
											transformOrigin:"center 60%"
										}} 
									/>
								</Box>
								<Stack sx={{ alignItems:'center'  }}>
									<Typography variant="body1" sx={{ textTransform: "capitalize", textAlign:'center' }}>
										{menu.name}
									</Typography>
									<Stack direction={"row"} spacing={1}>
										<Typography sx={{ textDecoration: isDiscount ? "line-through" : "none", fontWeight: isDiscount ? 500 : "bold" }}>£{menu.sale_price}</Typography>
										{isDiscount && (
											<Typography color="red" variant="body1">
												{currency?.symbol?.gbp}
												{fCurrency(menu.discount_price)}
											</Typography>
										)}
									</Stack>
								</Stack>
							</Stack>
						</Grid>
					)})
				}
			</Grid>
		</Box>
	);
};

export default MenuMCakeGrid;
