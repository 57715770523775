
import { useTheme } from '@mui/material/styles';
import { 
  Box, Button, Grid, Typography, useMediaQuery, Link,
  Dialog, DialogContent,IconButton,
 } from "@mui/material";
import ImageBox from "components/ui/ImageBox";
import SafeCarouel from 'components/ui/carousel';
import Review from "components/ui/review";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { path } from 'config/path';
import InstagramEmbed from 'components/page/menu/instagramEmbed';
import { StoreContext } from "../../layout/HomeLayout/index";
import { MenuMCakeCategoryGrid } from "components/page/menu/cake";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Phone } from "@mui/icons-material";
import { snsLink } from 'layout/HomeLayout/footer';
import CloseIcon from "@mui/icons-material/Close";

const PromoPopup = ({popup=null}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false); // You can control this state based on your logic

  useEffect(() => {
    if (popup) setOpen(true);
  },[popup]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick =() => {
    console.log('click');
    //setOpen(false);
    navigate(popup.note)
  }
  if (!popup) return <></>

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        //maxWidth="sm"
        //fullWidth
        PaperProps={{
          sx: {
            position: "relative",
            overflow: "hidden", 
            width : "calc(100%-32px)",
          },
        }}
      >
        <DialogContent sx={{ padding: 0, position: "relative" }}>
          {/* Close Button */}
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              padding : 0,
              top: 2,
              right: 2,
              color: "lightgrey", 
              zIndex: 1, 
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Promotion Image */}
          <Box
            component="img"
            src={`${path.ciHost}/uploads/${popup.store_id}/` + popup.filename} //{`${path.basename}/images/choice/popup_promo.jpg`}
            alt="Promotion"
            sx={{ width: "100%", height: "auto" }}
            onClick={handleClick}
          />
        </DialogContent>
      </Dialog>
  )
}

const Home = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const { allmenu, store } = useContext(StoreContext);
	const [cat, setCat] = useState([]);
  const [popup, setPopup] = useState(null);
  
	// USE EFFECTS
	useEffect(() => {
		let categories = [];
		allmenu.forEach((values, keys) => {
			categories.push({ name: keys, sub: values });
		});
		setCat(categories);
    let imgs = store?.images?.filter(image=>image.type == 3); // get popup
    console.log(imgs);
    if (imgs?.length>0) {
      setPopup(imgs[0]);
    }
	}, [allmenu, store]);
  //console.log(matchesXs);
  return (
    <Box display='flex' sx={{ 
      mx:'-0.4em', 
      flexWrap:'wrap', 
      fontSize:{xs:'9px', sm:'10px', md:'15px'} 
      }}
    >
      
      <PromoPopup popup={popup}/>
     
      <Box display='flex' width='100%' sx={{ justifyContent: 'space-between', alignItems: 'center', py: '1em', px: 2, backgroundColor : 'black' }}>
        <Link href = {`tel:${store.phone}`} sx={{ display: 'flex', alignItems: 'center', color:'white' }}>
          <Phone /><Typography variant="body1" fontFamily="sans-serif" >&nbsp;&nbsp;{store.phone}</Typography>
        </Link>
        <Box sx={{ color : 'white'}}>
          {snsLink(store.sns)}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {snsLink(store.sns2)}
        </Box>
      </Box>

      <Box display='flex' width='100%' sx={{ flexDirection:'column'}}>        
        {/* <InstagramEmbed /> */}
        <Box>
          <SafeCarouel store={store}/>
        </Box>
        <Box sx={{ 
          display:'flex', 
          justifyContent:'center', 
          alignItems:'center', 
          flexDirection:'column',
        }}>
          <ImageBox imageUrl={`${path.basename}/images/choice/choicebg2.png`} isBg={true}>          
            <Box width='80%' sx={{ py:'1em', textAlign:'center' }}>
              <Typography variant="span" sx={{ 
                fontFamily: 'Broadway BT, sans-serif', 
                fontSize: '2.5em', 
                lineHeight: '2em'
              }}>
                CHOI'CE CAKE
              </Typography>
              <Typography variant="span" sx={{ 
                fontSize:'2em',
                lineHeight: '2em' 
              }}>
                &nbsp;is always with you.
                The best handmade cake for special occasion.
              </Typography>
            </Box>
            <Typography variant="body2" sx={{
              px:{xs:'2em', md:'5em'},
              fontSize:'1.2em',
              textAlign:'center',
              pb:'1em',
              fontFamily:'sans-serif',
            }}>
            At CHOI'CE CAKE & COFFEE, our cakes are especially baked with quality ingredients Free-range eggs, and French gourmet Butter that ensure delectable pleasure with each mouthful. Our very own Pâtissier and Director, Mrs Choi is behind the making of our cafe's success.We started operating at Kingston Road, New Malden UK, in 2018. Since then, we’ve seen plenty of changes in the way people approach cakes and sweets. We noticed that many people are looking for different alternatives to sugary cakes for dietary needs so feel free to come and check us out in CHOI'CE CAKE & COFFEE and discover something delicious today.
            </Typography>
            <Button 
              onClick={() => navigate('./about')}
              variant='contained' 
              color='secondary'
              sx={{ mb:'1em', width : 'auto'}} 
            >
              Meet Mrs Choi
            </Button>
          </ImageBox>
        </Box> 
        <Box>				 
				{
					cat.map((category, index) => { 	
						return (
							<MenuMCakeCategoryGrid key={index} name={category.name} category={category.sub} bHead={false} /> 
						)
					})
				}
				</Box>
        <Box display='flex' sx={{ flexWrap:'wrap', alignItems:'center' }}>
          <Box width={{xs:'100%', md:'50%'}}>
            <Review />
          </Box>
          <Box display='flex' sx={{
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            p : '1em',
            width:{xs:'100%', md:'50%'},
          }}>
            <Typography variant='h2' sx={{ my:'1em', fontSize:'2em' }}>
              Opening Hours
            </Typography>
            <Grid container rowSpacing={{xs:3.5, md:5}} columnSpacing={{xs:1, md:3}} 
              sx={{fontSize:'1.5em', pb:'1em' }}
            >
              <Grid item xs={5} md={6} sx={{ textAlign:'right' }}>Monday</Grid>
              <Grid item xs={7} md={6}>9 am - 6 pm</Grid>
              <Grid item xs={5} md={6} sx={{ textAlign:'right' }}>Tuesday</Grid>
              <Grid item xs={7} md={6}>9 am - 7 pm</Grid>
              <Grid item xs={5} md={6} sx={{ textAlign:'right' }}>Wednesday</Grid>
              <Grid item xs={7} md={6}>9 am - 6 pm</Grid>
              <Grid item xs={5} md={6} sx={{ textAlign:'right' }}>Thursday</Grid>
              <Grid item xs={7} md={6}>9 am - 7 pm</Grid>
              <Grid item xs={5} md={6} sx={{ textAlign:'right' }}>Friday</Grid>
              <Grid item xs={7} md={6}>9 am - 7 pm</Grid>
              <Grid item xs={5} md={6} sx={{ textAlign:'right' }}>Saturday</Grid>
              <Grid item xs={7} md={6}>9 am - 6:30 pm</Grid>
              <Grid item xs={5} md={6} sx={{ textAlign:'right' }}>Sunday</Grid>
              <Grid item xs={7} md={6}>Closed</Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
